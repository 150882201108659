type GraphQLError = {
  locations: Array<{
    column: number;
    line: number;
  }>;
  message: string;
  path: Array<string>;
};

export type ExtensiveError = Error & {
  request?: {
    query: string;
    variables: any;
  };
  response?: {
    data: any;
    errors: Array<GraphQLError>;
  };
  code?: string;
};

let errorCodes: { [key: string]: string } = {
  out_of_stock: 'Artikel is niet meer in voorraad.',
  order_already_paid: 'Deze bestelling is al betaald.',
  internal_error: 'Er is een interne fout opgetreden, als dit blijft gebeuren neem aub contact met ons op.',
  payment_creation_failed:
    'Kon de betaling niet aanmaken, probeer opnieuw en neem aub contact met ons op als dit blijft gebeuren.',
  not_found: 'Kan het niet vinden.',
  invalid_shipping_details: 'Verzendgegevens zijn ongeldig.',
};

function getErrorMessageForCode(code: string) {
  let message = errorCodes[code];

  if (!message) {
    return { message: code, foundCode: false };
  } else {
    return { message: message, foundCode: true };
  }
}

export function stringifyGraphQLErrors(errors: Array<GraphQLError>) {
  let res = '';
  for (let e of errors) {
    res += getErrorMessageForCode(e.message).message + '\n';
  }
  return res;
}

export default function getErrorMessage(e: ExtensiveError): string {
  console.log({ ...e });
  if (e.code && errorCodes[e.code]) {
    return getErrorMessageForCode(e.code).message;
  }

  if (e.response) {
    return stringifyGraphQLErrors(e.response.errors);
  }

  return e.message;
}
