import * as React from 'react';

import { createContext } from './create-context';
import useCart, { Cart, AddToCart, SetCartCallback } from '../hooks/useCart';
import useDiscountCode, { SetDiscountCode } from '../hooks/useDiscountCode';
import { DiscountCodeObjectType } from '../../generated/graphql';

interface ProviderProps {
  children: React.ReactNode;
}

interface ProviderValue {
  cart: Cart;
  removeFromCart: AddToCart;
  addToCart: AddToCart;
  clearCart: () => void;
  setCart: SetCartCallback;
  discountCode: DiscountCodeObjectType | null;
  setDiscountCode: SetDiscountCode;
}

let [useContext, ReactProvider, ReactConsumer] = createContext<ProviderValue>();

export function Provider(props: ProviderProps) {
  let { children } = props;

  let { cart, removeFromCart, addToCart, clearCart, setCart } = useCart();
  let [code, setCode] = useDiscountCode();

  return (
    <ReactProvider
      value={{ setCart, cart, clearCart, removeFromCart, discountCode: code, setDiscountCode: setCode, addToCart }}
    >
      {children}
    </ReactProvider>
  );
}

export const usePageContext = useContext;
export const Consumer = ReactConsumer;
