import React from 'react';
import { AppProps } from 'next/app';
import splitbee from '@splitbee/web';

import sentry from '../utils/sentry';
import { Provider } from '../context/page-provider';
import ErrorBoundary from '../components/error-boundary';

import '../styles/style.css';
import Title from '../components/elements/title';
import { usePersistedLocale } from '../hooks/useLocaleUtils';

export const { Sentry, captureException } = sentry();

if (typeof window !== 'undefined') {
  console.log('%cStop!', 'font: 4em sans-serif; color: red;');
  console.log(
    '%cThis is a browser feature intended for developers. If someone told you to copy-paste something here to enable a feature or “hack” someone’s account, it is a scam and will give them access to your account.',
    'font: 1.5em sans-serif;'
  );

  splitbee.init({
    token: '9MSV47X0TGOT',
    // disableCookie: true, // Privacy mode, we can remove cookie disclaimer now :)
    scriptUrl: '/bee.js',
    apiUrl: '/_hive',
  });
}

const MyApp: React.FC<AppProps> = (props) => {
  const { Component, pageProps } = props;

  usePersistedLocale();

  const isInternetExplorer = React.useMemo(() => {
    return typeof window !== 'undefined' && /Trident\/|MSIE/.test(window.navigator.userAgent);
  }, []);

  return isInternetExplorer ? (
    <div className="vh-100 w-1/2 mx-auto mt-12">
      <Title>Je gebruikt een verouderde webbrowser.</Title>
      <div className="text-raw-umber">
        Om te garanderen dat onze webshop optimaal werkt, moet u gebruik maken één van de volgende browsers:{' '}
        <a href="https://www.microsoft.com/en-us/edge" className="underline">
          Microsoft Edge
        </a>
        ,{' '}
        <a href="https://www.apple.com/safari/" className="underline">
          Safari
        </a>
        ,{' '}
        <a href="https://www.mozilla.org/en-US/firefox/new/" className="underline">
          Google Chrome
        </a>{' '}
        or{' '}
        <a href="https://www.google.com/chrome/" className="underline">
          Mozilla Firefox
        </a>
      </div>
    </div>
  ) : (
    <ErrorBoundary>
      <Provider>
        <Component {...pageProps} />
      </Provider>
    </ErrorBoundary>
  );
};

export default function App(props: AppProps) {
  return (
    <>
      <link
        href="https://fonts.googleapis.com/css2?family=Bellefair&family=Montserrat:wght@400;500&display=swap"
        rel="stylesheet"
      />
      <MyApp {...props} />
    </>
  );
}
