import React from 'react';
import classNames from 'classnames';

type Props = {
  children: React.ReactNode;
  sideElement?: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
};

export default function Title(props: Props) {
  let { children, className, style, sideElement } = props;

  return (
    <h1 className={classNames('flex justify-between border-b-2 border-raw-umber pb-1 mb-4', className)} style={style}>
      <span className="font-sans font-medium text-raw-umber text-2xl">{children}</span>
      <div className="self-center">{sideElement}</div>
    </h1>
  );
}
