import EventEmitter from 'eventemitter3';

import getErrorMessage from '../utils/get-error-message';
import { captureException } from '../pages/_app';

let notificationEmitter = new EventEmitter();

type NotificationType = 'error' | 'warning' | 'success' | 'info';

type NotificationValue = {
  title: string;
  content: string;
};

type ErrorNotificationValue = {
  title: string;
  error: Error | string;
};

export type Notification = {
  type: NotificationType;
  data: NotificationValue;
};

export function emit(data: NotificationValue, type: NotificationType = 'info') {
  notificationEmitter.emit('notification', {
    type,
    data,
  });
}

export function info(data: NotificationValue) {
  emit(data, 'info');
}

export function success(data: NotificationValue) {
  emit(data, 'success');
}

export function warning(data: NotificationValue) {
  emit(data, 'warning');
}

export function error(data: ErrorNotificationValue) {
  captureException(data.error);

  let content = '';
  if (data.error instanceof Error) {
    content = getErrorMessage(data.error);
  } else {
    content = data.error;
  }

  emit(
    {
      title: data.title,
      content,
    },
    'error'
  );
}

export default notificationEmitter;
