import { useRouter } from 'next/router';
import { useEffect } from 'react';

const STORAGE_KEY = 'locale_overwrite';

export function usePersistedLocale() {
  const router = useRouter();

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const storedLocale = localStorage.getItem(STORAGE_KEY);
      if (storedLocale && router.locales?.length && router.locales.includes(storedLocale)) {
        router.push({ pathname: router.pathname, query: router.query }, router.asPath, { locale: storedLocale });
      }
    }
  }, [typeof window]);
}

export function useLocaleUtils() {
  const router = useRouter();
  const locale = router.locale ?? 'nl';

  const changeLocale = (newLocale: string) => {
    window.localStorage.setItem('locale_overwrite', newLocale);
    router.push({ pathname: router.pathname, query: router.query }, router.asPath, { locale: newLocale });
  };

  return { locale, changeLocale };
}
