export function hasLocalStorage(): boolean {
  if (typeof localStorage !== 'undefined') {
    let test = 'test';
    try {
      localStorage.setItem(test, test);
      localStorage.removeItem(test);
      return true;
    } catch (e) {}
  }

  if (typeof alert !== 'undefined') {
    alert('You are using an outdated browser, please update to have the full experience.');
  }

  return false;
}

export function readItem(key: string) {
  if (hasLocalStorage()) {
    try {
      let raw = localStorage.getItem(key);
      if (raw) {
        return JSON.parse(raw);
      }
    } catch (e) {}
  }

  return undefined;
}

export function writeItem(key: string, val: any): any {
  if (hasLocalStorage()) {
    try {
      let raw = JSON.stringify(val);
      localStorage.setItem(key, raw);
    } catch (e) {}
  }
}

export function removeItem(key: string): any {
  if (hasLocalStorage()) {
    try {
      localStorage.removeItem(key);
    } catch (e) {}
  }
}
