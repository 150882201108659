import React from 'react';

import { readItem, writeItem } from '../utils/localstorage';

const LOCALSTORAGE_CART_KEY = '__gypsy_cart_v1';

export type CartItem = { id: string; size: string; amount: number };
export type Cart = Array<CartItem>;
export type AddToCart = (item: CartItem) => void;
export type ClearCart = () => void;
export type SetCartCallback = (newCart: Cart) => void;

const getCart = () => {
  if (typeof window !== 'undefined') {
    let storedCart = readItem(LOCALSTORAGE_CART_KEY) || [];
    let invalidItem = storedCart.find((i: any) => !(typeof i === 'object' && i.amount && i.id && i.size));
    if (!invalidItem) {
      return storedCart;
    }
  }
};

export default function useCart(): {
  cart: Cart;
  removeFromCart: AddToCart;
  addToCart: AddToCart;
  clearCart: ClearCart;
  setCart: SetCartCallback;
} {
  let [cart, setCart] = React.useState<Array<CartItem>>([]);

  React.useEffect(() => {
    let existingCart = getCart();
    if (existingCart) {
      setCart(existingCart);
    }
  }, [typeof window]);

  const updateCart = (newCart: Cart) => {
    writeItem(LOCALSTORAGE_CART_KEY, newCart);
    // @ts-ignore
    setCart(newCart);
  };

  const addToCart = (item: CartItem) => {
    let cartClone = [...cart];
    let index = cartClone.findIndex((p: CartItem) => {
      return p.id === item.id && p.size === item.size;
    });
    if (index >= 0) {
      cartClone.splice(index, 1);
    }
    cartClone.push(item);
    updateCart(cartClone);
  };

  const removeFromCart = (item: CartItem) => {
    let cartClone = [...cart];
    let index = cartClone.findIndex((p: CartItem) => p.id === item.id && p.size === item.size);
    if (index >= 0) {
      cartClone[index].amount -= item.amount;
      if (cartClone[index].amount === 0) {
        cartClone.splice(index, 1);
      }
      updateCart(cartClone);
    }
  };

  const clearCart = () => {
    updateCart([]);
  };

  return { cart, addToCart, removeFromCart, clearCart, setCart: updateCart };
}
