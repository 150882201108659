import dayjs from 'dayjs';

import 'dayjs/locale/nl';

export function formatDateTime(timestamp: number | string) {
  return dayjs(timestamp).format('DD-MM-YYYY HH:mm');
}

export function formatDate(timestamp: number | string) {
  return dayjs(timestamp).format('DD-MM-YYYY');
}

export function dateString(timestamp: number | string | Date, locale: string = 'nl') {
  return dayjs(timestamp).locale(locale).format('dddd, DD MMMM YYYY');
}

export function toISOString(timestamp: number | string) {
  return dayjs(timestamp).toISOString();
}

export function compareDates(dateOne: number | string, dateTwo: number | string) {
  return dayjs(dateOne).unix() - dayjs(dateTwo).unix();
}
