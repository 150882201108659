import React from 'react';

import { readItem, writeItem, removeItem } from '../utils/localstorage';
import { compareDates } from '../utils/date-utils';
import { error } from '../event-emitters/notifications';
import { DiscountCodeObjectType } from '../../generated/graphql';

const DISCOUNT_EXPIRY_DIFF_TRESHOLD = 15 * 60;
const LOCALSTORAGE_KEY = 'discount-code';

export type SetDiscountCode = (code: DiscountCodeObjectType | null) => any;

export default function useDiscountCode(): [DiscountCodeObjectType | null, SetDiscountCode] {
  let [code, setCode] = React.useState<DiscountCodeObjectType | null>(null);

  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      try {
        let discountCode = readItem(LOCALSTORAGE_KEY);
        if (discountCode && discountCode.expiryDate) {
          let diff = compareDates(discountCode.expiryDate, Date.now());
          if (diff <= DISCOUNT_EXPIRY_DIFF_TRESHOLD) {
            removeItem(LOCALSTORAGE_KEY);
            return;
          }

          setCode(discountCode);
        }
      } catch (e: any) {
        error(e);
      }
    }
  }, [typeof window]);

  const updateCode = (newCode: DiscountCodeObjectType | null) => {
    writeItem(LOCALSTORAGE_KEY, newCode);
    setCode(newCode);
  };

  return [code, updateCode];
}
