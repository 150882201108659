import * as Sentry from '@sentry/nextjs';
import cookie from 'cookie';

const SENTRY_DSN = 'https://a1c49e316a98471a8b78b1f012f1f50a@sentry.io/1540726';

const initSentry = (release = process.env.SENTRY_RELEASE) => {
  let sentryOptions = {
    dsn: SENTRY_DSN,
    release,
    maxBreadcrumbs: 50,
    attachStacktrace: true,
  };

  // When we're developing locally
  if (process.env.NODE_ENV !== 'production') {
    const SentryIntegrations = require('@sentry/integrations');

    // Don't actually send the errors to Sentry
    // @ts-ignore
    sentryOptions.beforeSend = () => null;

    // Instead, dump the errors to the console
    // @ts-ignore
    sentryOptions.integrations = [
      new SentryIntegrations.Debug({
        // Trigger DevTools debugger instead of using console.log
        debugger: false,
      }),
    ];
  }

  Sentry.init(sentryOptions);

  return {
    Sentry,
    // @ts-ignore
    captureException: (err, ctx?: any) => {
      // @ts-ignore
      Sentry.configureScope((scope) => {
        if (err.message) {
          // De-duplication currently doesn't work correctly for SSR / browser errors
          // so we force deduplication by error message if it is present
          scope.setFingerprint([err.message]);
        }

        if (err.statusCode) {
          scope.setExtra('statusCode', err.statusCode);
        }

        if (ctx) {
          let { req, res, errorInfo, query, pathname } = ctx;

          if (res && res.statusCode) {
            scope.setExtra('statusCode', res.statusCode);
          }

          if (typeof window !== 'undefined') {
            scope.setTag('ssr', false);
            scope.setExtra('query', query);
            scope.setExtra('pathname', pathname);

            // On client-side we use js-cookie package to fetch it
            let cookieContent = cookie.parse(document.cookie);
            let sessionId = cookieContent.sid;
            if (sessionId) {
              scope.setUser({ id: sessionId });
            }
          } else {
            scope.setTag('ssr', true);
            scope.setExtra('url', req.url);
            scope.setExtra('method', req.method);
            scope.setExtra('headers', req.headers);
            scope.setExtra('params', req.params);
            scope.setExtra('query', req.query);

            // On server-side we take session cookie directly from request
            if (req.cookies && req.cookies.sid) {
              scope.setUser({ id: req.cookies.sid });
            }
          }

          if (errorInfo) {
            Object.keys(errorInfo).forEach((key) => scope.setExtra(key, errorInfo[key]));
          }
        }
      });

      return Sentry.captureException(err);
    },
  };
};

export default initSentry;
