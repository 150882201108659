import * as React from 'react';

// Typescript helper for creating contexts...
export function createContext<V>(): [() => V, React.Provider<V>, React.Consumer<V>] {
  let ctx = React.createContext<V | undefined>(undefined);

  function useContext() {
    let c = React.useContext(ctx);
    if (!c) throw new Error('useCtx must be inside a Provider with a value');
    return c;
  }

  // @ts-ignore
  return [useContext, ctx.Provider, ctx.Consumer];
}
